<template>
  <div>
    <section class="selfcare-title d-flex">
      <div class="px-2">
        <a @click="goBack()" class="custom-link text-light">
          <i class="bi bi-arrow-left"></i>
        </a>
      </div>
      <div class="text-light width-available text-center">
        <strong> Responsible Gamming </strong>
      </div>
    </section>
    <section class="selfcare-title">
      <div class="px-1 textColor" v-html="content"></div>
    </section>
    <BottomNavigation></BottomNavigation>
  </div>
</template>

<script>
import axios from "@/services/cms";

import BottomNavigation from "@/components/BottomNavigation";

export default {
  name: 'Responsible',
  components: {
  
    BottomNavigation
  },
  data(){
    return{
      content:'',
    }
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage","responsible");
    this.reloadProfile();
    this.getRespGam();

  },
  methods:{
    async getRespGam() {
      try {
        const response = await axios.get("/get/resgamming", {
          headers: {
            Accept: "application/json",
          },
        });
        if (response.status === 200) {
          this.content = response.data.content; 
          console.log(response.data);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching responsible gamming",
          error.response?.data || error.message
        );
      }
    },
  }

}
</script>
<style>
.textColor{
  color: black;
}
</style>