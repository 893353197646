<template>
  <div>
    <section class="selfcare-title d-flex">
      <div class="px-2">
        <a @click="goBack()" class="custom-link text-light">
          <i class="bi bi-arrow-left"></i>
        </a>
      </div>
      <!-- <div class="text-light width-available text-center"> -->
        <!-- <strong> Terms and Conditions </strong> -->
      <!-- </div> -->
    </section>
    <section class="selfcare-title ">
      <div class="px-1 textColor" v-html="content"></div>
    </section>
  </div>
</template>

<script>
import axios from "@/services/cms";

export default {
  name:"Terms",
  components: {},
  data() {
    return {
      content: "",
    };
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "terms");
    this.reloadProfile();

    this.getTerms();
  },
  methods: {
    async getTerms() {
      try {
        const response = await axios.get("/get/tnc", {
          headers: {
            Accept: "application/json",
          },
        });
        if (response.status === 200) {
          this.content = response.data.content; // Get the HTML content from the editor
          console.log(response.data);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching terms and conditions",
          error.response?.data || error.message
        );
      }
    },
  }
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  padding: 20px;
 
}
.textColor{
  color: black;
}

h1, h2 {
  margin-top: 30px;
  margin-bottom: 20px;
}

h2{
  font-size: 1.5em;
}

p {
  margin-bottom: 10px;
}

.definition-list {
  margin-top: 20px;
}

.definition-list dt {
  font-weight: bold;
}

.definition-list dd {
  margin-bottom: 10px;
}
</style>