<template>
  <div>
    <section class="selfcare-title d-flex">
      <div class="px-2">
        <a @click="goBack()" class="custom-link text-light">
          <i class="bi bi-arrow-left"></i>
        </a>
      </div>
      <div class="text-light width-available text-center">
        <strong> Frequently Asked Questions </strong>
      </div>
    </section>
    <section 
  class="selfcare-title d-flex" 
  v-for="(faq, index) in content" 
  :key="index"
>
  <div class="px-1">
    <section class="promo-card-wrapper mb-3">
      <div 
        class="text-dark p-2" 
        data-toggle="collapse" 
        :data-target="'#collapse-' + index" 
        aria-expanded="false" 
        :aria-controls="'collapse-' + index"
      >
        <strong>{{ faq.title }}</strong>
      </div>
      <div 
        class="promo-content text-dark collapse p-2" 
        :id="'collapse-' + index"
      >
        <!-- Some HTML static content -->
        {{ faq.description }}
      </div>
    </section>
  </div>
</section>
  </div>
</template>

<script>
import axios from "@/services/cms";

export default {
  name: "Faq",
  components: {},
  data() {
    return {
      content: [],
    };
  },
  mounted: function () {
    this.$store.dispatch("setCurrentPage", "terms");
    this.reloadProfile();
    this.getFaq();
  },
  methods: {
    async getFaq() {
      try {
        const response = await axios.get("/get/faq", {
          headers: {
            Accept: "application/json",
          },
        });
        if (response.status === 200) {
          this.content = response.data.faq; // Get the HTML content from the editor
          console.log(response.data);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching frequently asked questions",
          error.response?.data || error.message
        );
      }
    },
  },
};
</script>
